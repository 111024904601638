<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='任务名称'>
              <el-input v-model='searchCondition.name' placeholder='任务名称' clearable></el-input>
            </el-form-item>
            <el-form-item label='任务类型'>
              <!--              <el-input v-model='searchCondition.type' placeholder='1 command,2 class,3 url,4 eval)' clearable></el-input>-->
              <el-select v-model='searchCondition.type' placeholder='1 command,2 class,3 url,4 eval)' clearable>
                <el-option v-for='(label,type) in types' :key='type' :value='type' :label='label'>{{ label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='执行方式'>
              <el-select v-model='searchCondition.singleton' placeholder='是否单次执行 (0 是 1 不是)' clearable>
                <el-option :value='`0`' label='单次执行'></el-option>
                <el-option :value='`1`' label='间隔执行'></el-option>
              </el-select>
              <!--              <el-input v-model='searchCondition.singleton' placeholder='是否单次执行 (0 是 1 不是)' clearable></el-input>-->
            </el-form-item>
            <el-form-item label='状态'>
              <!--              <el-input v-model='searchCondition.status' placeholder='状态 (0正常 1停用)' clearable></el-input>-->
              <el-select v-model='searchCondition.status' placeholder='状态 (0正常 1停用)' clearable>
                <el-option :value='`0`' label='正常'>正常</el-option>
                <el-option :value='`1`' label='停用'>停用</el-option>
              </el-select>
            </el-form-item>

            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                         @click='handleQuery'>搜索
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-button type='default' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
<!--        <el-row class='mb8' :gutter='10'>-->
<!--          <el-col :span='1.5'>-->
<!--            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <el-row :gutter='15'>
          <el-col :xl='6' :lg='6' :md='8' :sm='12' :xs='24' v-for='(item,index) in list' :key='item.id'>
            <el-card class='task task-item' shadow='hover'>
              <span style='font-weight: bold'>{{ offset + index + 1 }}) {{ item.name }} 【{{ getTypeLabel(item.type)
                }}】</span>
              <div class='info-item'>
                <div class='info-left'>
                  <ul>
                    <li>
                      <h4>调用目标</h4>
                      <p>{{ item.target }}</p>
                    </li>
                    <li>
                      <h4>定时规则</h4>
                      <p>{{ item.rule }}</p>
                    </li>
                  </ul>
                </div>
                <div class='info-right'>
                  <div>
                    创建时间： {{ item.created_at }}
                    <br />
                    修改时间：{{ item.updated_at }}
                    <br />
                    执行次数：{{ item.exec_count || 0 }}
                  </div>
                </div>
              </div>
              <div class='bottom'>
                <div class='state'>
                  <el-tag v-if="item.status=='0'" type='success' effect='plain'>正常</el-tag>
                  <el-tag v-if="item.status=='1'" type='info' effect='plain'>停用</el-tag>
                </div>
                <div class='state'>
                  <el-tag v-if="item.singleton=='0'" type='warning' effect='plain'>单次执行</el-tag>
                  <el-tag v-if="item.singleton=='1'" type='success' effect='plain'>间隔执行</el-tag>
                </div>
                <div class='handler'>
                  <el-popconfirm title='确定立即执行吗？' @confirm='run(item)'>
                    <template #reference>
                      <el-button type='primary' icon='el-icon-caret-right' circle></el-button>
                    </template>
                  </el-popconfirm>
                  <div style='display: inline;margin-left: 10px'>
                    <el-button icon='el-icon-edit-outline' @click='edit(item)' circle></el-button>
                    <el-button icon='el-icon-document' @click='logs(item)' circle></el-button>
                    <el-button icon='el-icon-delete' @click='del(item)' circle></el-button>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <pagination :hidden='total===0'
                        :background='true'
                        :total.sync='total'
                        :page.sync='pageData.current_page'
                        :limit.sync='pageData.page_size'
                        @pagination='getList' />
          </el-col>
        </el-row>
        <el-divider content-position='left'> 添加定时任务</el-divider>
        <el-row>
          <el-col :span='6'>
            <el-card class='task task-add' shadow='none'>
              <span @click='handleAdd'> <i class='el-icon-plus'></i>
                <p>添加计划任务</p>
              </span>
            </el-card>
          </el-col>
        </el-row>
      </div>


    </PageHeaderLayout>
    <save-dialog v-if='dialog.save' ref='saveDialog' @success='handleSuccess' @closed='dialog.save=false'></save-dialog>

    <el-drawer title='计划任务日志' :visible.sync='dialog.logsVisible' :size='700' direction='rtl' append-to-body
               destroy-on-close>
      <logs ref='logList' />
    </el-drawer>
  </div>
</template>

<script>
import saveDialog from './save'
import logs from './logs'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'CrontabIndex',
  components: {
    PageHeaderLayout,
    saveDialog,
    logs
  },
  data() {
    return {
      dialog: {
        save: false,
        logsVisible: false
      },
      searchCondition: {
        date_range: [],
        name: null,
        type: null,
        target: null,
        parameter: null,
        rule: null,
        singleton: null,
        status: null,
        created_by: null,
        updated_by: null,
        remark: null
      },
      list: [],
      types: { '1': '命令任务', '2': '类任务', '3': 'URL任务', '4': 'EVAL任务' },
      total: 0,
      offset: 0,
      pageData: {
        current_page: 1,
        page_size: 15
      },
      orderBy: { id: 'asc' },
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:setting_crontab',
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },

  mounted() {
    // this.getList()
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  },
  methods: {
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })
      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },

    // 载入数据
    async getList() {
      this.list = []
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getCrontabPageList(params)
      this.$nextTick(() => {
        this.list = list
        this.pageData.current_page = pages.current_page
        this.pageData.page_size = pages.page_size
        this.total = pages.total
        this.offset = pages.offset
      })

    },

    // 获取类型标签
    getTypeLabel(key) {
      return this.types[key]
    },

    // 新增
    handleAdd() {
      this.dialog.save = true
      this.$nextTick(() => {
        this.$refs.saveDialog.open()
      })
    },
    // 编辑row
    edit(row) {
      this.dialog.save = true
      this.$nextTick(() => {
        this.$refs.saveDialog.open('edit').setData(row)
      })
    },

    // 删除
    del(row) {
      this.$confirm(`确认删除 ${row.name} 定时任务吗？`, '提示', {
        type: 'warning',
        confirmButtonText: '删除',
        confirmButtonClass: 'el-button--danger'
      }).then(async () => {
        let res = await this.$api.deleteCrontab(row.id)
        console.log(res)
        if (res === 'ok') {
          this.$message.success('操作成功')
          await this.loadData()
        } else {
          this.$message.error('操作失败')
        }
      }).catch(() => {
      })
    },

    // 定时任务日志
    // eslint-disable-next-line
    logs(row) {
      this.dialog.logsVisible = true
      this.$nextTick(() => {
        if (this.$refs.logList)
          this.$refs.logList.setData(row)
      })
    },

    // 立刻执行定时任务
    run(row) {
      this.$api.runCrontab({ id: row.id }).then(() => {
        this.$message.success(`已成功执行计划任务：${row.name}`)
      })
    },

    //本地更新数据
    // eslint-disable-next-line
    handleSuccess(data, mode) {
      this.loadData()
    }
  }
}
</script>

<style scoped lang='scss'>
.task {
  height: 248px;
  margin-bottom: 10px;
}

.task-item h2 {
  font-size: 15px;
  color: #3c4a54;
  padding-bottom: 15px;
}

.task-item li {
  list-style-type: none;
  margin-bottom: 10px;
}

.task-item li h4 {
  font-size: 12px;
  font-weight: normal;
  color: #999;
}

.task-item li p {
  margin-top: 5px;
}

.task-item .bottom {
  border-top: 1px solid #EBEEF5;
  text-align: right;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-item {
  display: flex;
  flex-direction: row;
  //两端对齐 中间等距
  justify-content: space-between;
}

.info-right {
  font-size: 12px;
  font-weight: normal;
  color: #999;
  padding: 10px;
  //border: #e97ca1 1px dashed;
  margin: 10px;
  vertical-align: bottom;
  line-height: 2;
  text-align: left;
  height: auto;
}

.info-left {
  font-size: 12px;
  font-weight: normal;
  color: #999;
  padding: 10px;
  //border: #e97ca1 1px dashed;
}

.task-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #999;
  z-index: 9999;
}

.task-add:hover {
  color: #ff3176;
  font-weight: bold;
}

.task-add i {
  font-size: 30px;
}

.task-add p {
  font-size: 12px;
  margin-top: 20px;
}

[data-theme='dark'] {
  .task-item h2 {
    color: #fff;
  }

  .task-item .bottom {
    border-top: 1px solid #383838;
  }
}
</style>
